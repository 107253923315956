<template>
<div class="container container-width">
    <h1 class="text-center text-danger mt-5">Page is Under Construction.</h1>
</div>
</template>

<script>
export default {
    name: 'UnderConstruction'
};
</script>

<style>

</style>
